@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
  background-color: #e57552 !important;
}

.footer {
  max-width: 1072px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 24px 32px;
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
  & a {
    color: #fff;
    font-family: 'Source Serif 4';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.64px;
  }
}

.grid {
  display: flex;
  flex: 1;
  justify-content: space-between;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
}

.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
  & svg {
    & path {
      fill: #fff;
    }
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;
  border-bottom: 4px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 16px;
  & img {
    width: 170px;
    height: 73px;
  }
  &:hover {
    text-decoration: none;
  }
}

.logoImage {
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
    & :global(#general) {
      & ul {
        /* & li:last-child {
          color: #fff;
          font-family: 'Source Serif 4';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.64px;
          & a{
            cursor: not-allowed;
          }
          position: relative;
          &:hover {
            &:after {
              content: 'Coming Soon';
              position: absolute;
              width: 150px;
              padding: 3px;
              background: #fff;
              color: var(--marketplaceColor);
              bottom: 110%;
              left: 50%;
              margin-bottom: 5px;
              margin-left: -80px;
              padding: 12px;
              width: 170px;
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              text-align: center;
              font-size: 14px;
              line-height: 21px;
            }
            &:before {
              position: absolute;
              bottom: 110%;
              left: 50%;
              margin-left: -5px;
              width: 0;
              border-top: 5px solid #fff;
              border-top: 5px solid #fff;
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
              content: ' ';
              font-size: 0;
              line-height: 0;
            }
          }
          
        } */
      }
    }
  }

  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
