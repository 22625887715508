@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}

.threeColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.fourColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.professionalHeading {
  color: #4a4a4a;
  text-align: center;
  font-size: 55px;
  font-family: 'Source Serif 4', serif;
  font-style: normal;
  width: 28ch;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.5px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 36px;
    line-height: 46px;
  }
}

.description {
  color: #4a4a4a;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.348px;
  margin-top: 28px !important;
  max-width: 77ch;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.howDoesBlock {
  display: flex !important;
  flex-wrap: wrap;
  padding-top: 43px !important;
  & :global(#how-does-details) {
    width: 100% !important;
    text-align: center;
    margin-bottom: 40px;
    & p {
      margin: 0 auto;
      color: #4a4a4a;
      text-align: center;
      font-family: 'Poppins';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
      letter-spacing: -0.348px;
      max-width: 800px;
    }
  }
  & > div {
    width: calc(33.33% - 32px);
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
