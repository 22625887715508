@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.heroContent {
  padding: 32px 0;
  position: relative;
  & > div {
    max-width: 1680px;
    & :global(#hero-block) {
      justify-content: flex-start;
      flex-direction: row;
      @media (--viewportLarge) {
        padding-left: 100px;
      }
    }
  }
  @media (--viewportMedium) {
    padding: 160px 0 224px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.howWorkSection {
  margin-bottom: 120px;
  & h3 {
    color: #4a4a4a;
    font-family: 'Inter';
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 152.381% */
    letter-spacing: -0.75px;
  }
  & > div {
    max-width: 1340px !important;
    @media (min-width: 1380px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.dailyTalentSection {
  max-width: 1342px;
  margin: 0 auto;
  padding-top: 102px;

  @media (max-width: 990px) {
    padding-top: 40px;
  }
  & header {
    & h2 {
      color: #fff;
      font-size: 55px;
      font-family: 'Source Serif 4';
      @media (max-width: 990px) {
        font-size: 36px;
      }
      /* box-shadow: 1px 2px 1px 0px #00000040 inset; */
    }
  }
}

.dailyTalent {
  background: #666241;
}

.howWorkSectionHeader {
  & header {
    display: block;
    max-width: 800px;
    @media (min-width: 860px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    & h2 {
    }
    & p {
      max-width: 672px;
      margin: 43px auto 0;
    }
  }
}
