.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.homeSearchBox {
  display: flex;
  width: 100%;
  max-width: 955px;
  border-radius: 100px;
  background: #fff;
  padding:18px 25px;
  box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 44%) inset;

  @media (max-width: 1024px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    box-shadow: none;
    border-radius: 3px;
    padding: 15px;
  }

  & .dropdownInput {
    width: 250px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 15px;
    }

    & select {
      font-family: 'Quicksand';

      color: #2f2d2b;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      box-shadow: none;
      background-size: auto;
      cursor: pointer;
      background-image: url(../../../../assets/images/arrow-bottom.png);

      @media (max-width: 768px) {
        height: 40px;
        border: 1px solid #333;
        border-radius: 3px;
        font-size: 18px;
      }
    }
  }

  & .inputBox {
    width: 300px;
    flex-wrap: wrap;
    position: relative;
    /* padding-left: 45px; */
    margin-left: 45px;
    display: flex;
    align-items: center;
    flex: 1;

    @media (max-width: 990px) {
      width: 200px;
      margin-left: 25px;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 15px;
      margin-left: 0px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      left: -22px;
      background-color: #605a5a;
      @media (max-width: 990px) {
        left: -12px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    & > div {
      border-bottom: none;

      & svg {
        display: none;
      }
    }

    & input {
      border: none;
      box-shadow: none;
      color:  #2F2D2B;
      font-family: 'Quicksand';
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      @media (max-width: 768px) {
        height: 40px;
        border: 1px solid #333;
        border-radius: 3px;
        font-size: 18px;
      }
    }
  }

  & a, button {
    border-radius: 100px;
    background: #2f2d2b;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 233px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 64px;
    font-family: "Source Serif 4";
    @media (max-width: 990px) {
      width: auto;
    }
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 3px;
      font-size: 18px;
      height: 40px;
    }
  }
}

.submitButtonWrapper{
  @media (max-width: 767px) {
    width: 100%;
  }
}

.heroBlockBox {
  & h1 {
    color: #ffffff;
    font-size: 80px;

    font-style: normal;
    font-weight: 900;
    line-height: 100px;
    letter-spacing: 0.56px;
    margin-bottom: 28px;
    font-family: Poppins;

    @media (max-width: 768px) {
      font-size: 38px;
      line-height: 40px;
    }
  }

  & > p {
    color: #ffffff;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0.8px;
    font-family: 'Source Serif 4', serif;
    margin: 0;
    padding: 0;
    @media (max-width: 1024px) {
      font-size: 40px;
      line-height: 60px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 33px;
   
    }

    @media (max-width: 460px) {
      font-size: 32px;
      line-height: 42px;
    }
  }
}

.submitButton {
  color: #fff;
  text-align: center;
  font-family: Source Serif 4;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2px;
  background: #2f2d2b;
  margin-left: auto;
  padding: 5px 25px;
  border-radius: 25px !important;
  cursor: pointer;
  @media (max-width: 768px) {
    border-radius: 3px !important;
  }
  &:hover {
    text-decoration: none;
  }
}

.filterWrapper {
  margin-top: 56px !important;
}

.fractionMedia {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0px;
  padding: 0 30px;
  @media (max-width: 990px) {
    margin-bottom: 34px;
  }
  & h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    line-height: 50px;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    margin: 10px 0 0;
    letter-spacing: -1.5px;
    text-align: center;
    @media (max-width: 1023px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.fractionMediaImage {
  & div {
    position: relative !important;
    padding: 0 !important;
    border-radius: 160px 160px 10px 10px;
    background: #666241;

    & img {
      border-radius: 160px 160px 10px 10px;
      height: 297px;
      max-width: 297px;
      opacity: 0;
      @media (max-width: 1023px) {
        height: 200px;
        max-width: 200px;
      }
      @media (max-width: 767px) {
        height: auto;
        max-width: 100%;
        aspect-ratio: 17/17;
      }
    }
  }
}

.slideranchorTag{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    text-decoration: none;
  }
}

.authPrompt{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.authPromptContent{
  background-color: #fff;
  padding:30px 30px;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.closeButton{
  position: absolute;
  top: 10px;
  right: 20px;  
  cursor: pointer;
}