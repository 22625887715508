
.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
.spotlight {
  /* color: #fff; */
  font-size: 55px;
  font-family: 'Source Serif 4';
  text-align: center;
  @media (max-width: 767px) {
    line-height: 1;
  }
}

:global(#daily-talent-spotlight) {
  & .spotlight {
    color: #fff !important;
  }
}

.talentSlider {
  margin: 60px 0;
  max-width: 100vw;
  @media (max-width: 990px) {
    /* max-width: calc(100vw - 0px); */
    width: 100%;
    padding: 0 22px;
  }
  & :global(.slick-disabled) {
    opacity: 0;
  }
  & :global(.slick-arrow) {
    top: 40%;
    @media (max-width: 990px) {
      & svg {
        height: 25px;
      }
    }
    &:before {
      content: unset !important;
    }
  }
  & :global(.slick-next) {
    @media (max-width: 767px) {
      right: 0px;

      & svg {
        & path {
          stroke: #ffa285;
        }
      }
    }
  }
  & :global(.slick-prev) {
    left: -45px;
    @media (max-width: 990px) {
      left: 0px;
      z-index: 10;
      & svg {
        & path {
          stroke: #ffa285;
        }
      }

      padding: 3px;
      & svg {
        height: 25px;
      }
    }
    &:before {
      content: unset !important;
    }
  }
}
