@import '../../../../styles/customMediaQueries.css';

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 64px 0 0 0;

  &:hover .carouselArrows {
    opacity: 1;
  }

  /* smartphones, touchscreens: we don't need to show arrows */
  @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      opacity: 0;
    }
  }
}

.carouselArrows {
  opacity: 0;
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;

  width: 48px;
  height: 48px;
  padding: 0px 0 4px 0;
  margin-top: -64px;
  border-radius: 100%;
  border: none;

  background-color: lightgrey;
  opacity: 0.9;
  font-size: 30px;
  color: black;
  transition: all ease-in-out 100ms;

  &:hover {
    opacity: 1;
    cursor: pointer;
    background: black;
    color: white;
  }
}

.carouselArrowPrev {
  left: 48px;
}

.carouselArrowNext {
  right: 48px;
}

.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.baseCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: var(--carouselWidth);
  padding-bottom: 120px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f9d3c7;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffaa8c;
  }
}

.oneColumn,
.twoColumns,
.threeColumns,
.fourColumns {
  composes: baseCarousel;
}

.block {
  flex: 0 0 auto;
  /* 64px = horizontal layout paddings */
  width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

  margin-right: 25px;
  scroll-snap-align: center;

  transform: translateX(32px);

  &:last-of-type {
    padding-right: 32px;
    /* 32px (padding-right above) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
  }

  @media (--viewportMedium) {
    margin-right: 43px;
  }

  @media (min-width: 1200px) {
    /* Offset the start of the carousel so it follows the global grid layout (e.g. (1400 - 1200) / 2 = 100px) */
    /* Removing this uses the full page width for the slider pane */
    transform: translateX(
      max(
        calc((var(--carouselWidth) - var(--contentMaxWidthPages)) / 2 + -97px),
        0px
      )
    );

    &:last-of-type {
      padding-right: 32px;
      width: calc(
        min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px
      );
      /* 32px (padding-right above) */
    }
  }
}

.oneColumn .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

    &:last-of-type {
      /* 32px (padding-right) */
      width: calc(
        min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px
      );
    }
  }
}

.twoColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (one gutter á 32px) / 2 (number of columns) */
    width: calc(
      (min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 32px) / 2
    );

    &:last-of-type {
      /* 32px (padding-right above) / 2 (number of columns) */
      width: calc(
        (min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 2
      );
    }
  }
}

.threeColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (two gutters á 32px) / 3 (number of columns) */
    width: calc(
      (min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 64px) / 3
    );

    &:last-of-type {
      /* 32px (padding-right above) / 3 (number of columns) */
      width: calc(
        (min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 3
      );
    }
  }
}

.fourColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 96px (three gutters á 32px) / 4 (number of columns) */
    width: calc(
      (min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 96px) / 4
    );

    &:last-of-type {
      /* 32px (padding-right above) / 4 (number of columns) */
      width: calc(
        (min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 4
      );
    }
  }
}

.professionalHeading {
  color: #4a4a4a;
  text-align: center;
  font-size: 55px;
  font-family: 'Source Serif 4', serif;
  font-style: normal;
  width: 28ch;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.5px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 36px;
    line-height: 46px;
  }
}

.landingSlider {
  position: relative;
  @media (min-width: 768px) {
    width: calc(
      (min(var(--contentMaxWidthPages), var(--carouselWidth)) - 30px - 96px) / 4
    ) !important;
  }
}

:global(#fractional-professionals-slider) {
  & > div:nth-child(even) {
    transition: all var(--transitionStyleButton);
    position: relative;
    & > div:not(:last-child) {
      opacity: 0;
    }
    & > div:nth-child(2) {
      background: #fcf6eb;
      border-radius: 160px 160px 10px 10px;
    }
    & h3 {
      color: #666241 !important;
    }
    &:hover {
      & > div:nth-child(2) {
        background: url('../../../../assets/landingSlider2.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        & > div {
          & > div > div {
          }
        }
      }
    }
  }
  & > div:nth-child(odd) {
    transition: all var(--transitionStyleButton);
    position: relative;
    & > div:not(:last-child) {
      opacity: 0;
    }
    & > div:nth-child(2) {
      background: #666241;
      border-radius: 160px 160px 10px 10px;
    }
    & h3 {
      color: #fff !important;
    }
    &:hover {
      & > div:nth-child(2) {
        background: url('../../../../assets/landingSlider1.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        & > div {
          & > div > div {
          }
        }
      }
      & h3 {
        font-weight: 800;
      }
    }
  }
}

.sliderId {
  background-color: #fcfcfc !important;
  padding-bottom: 60px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-top: 40px;
  }
  & > div {
    @media (max-width: 767px) {
      padding: 0 22px;
    }
    max-width: 1342px;
    margin: 0 auto;
  }
  & header {
    & h2 {
      color: #4a4a4a;
      text-align: center;
      font-family: 'Source Serif 4';
      font-size: 55px;
      font-style: normal;
      font-weight: 700;
      @media (max-width: 990px) {
        font-size: 32px;
      }
    }
  }
  & header ~ div {
    padding-top: 102px;
    @media (max-width: 990px) {
      padding-top: 40px;
    }
  }
}
